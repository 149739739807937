/* eslint-disable camelcase, no-underscore-dangle */
import uuidv1 from 'react-uuid';
import templates from './templates';

export default async function initAnalytics(info) {
  // make sure tms datalayer arrays set
  window._mtm = window._mtm || [];
  window.dataLayer = window.dataLayer || [];

  if (info.preventTracking) return;

  // eslint-disable-next-line no-console
  console.log('analytics v1');

  // environment
  const gtm_container = process.env.NEXT_PUBLIC_GTM_CONTAINER;
  const mtm_container = process.env.NEXT_PUBLIC_MTM_CONTAINER || '';
  const systemEnvironment = process.env.NEXT_PUBLIC_SYSTEM_ENV;
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
  const locale = 'en-US'; // This will be variable in the future when we implement languages

  // check if uuid exists in localStorage and set a new one if none exists
  let uuid = localStorage.getItem('uuid');
  if (uuid === null) {
    localStorage.setItem('uuid', uuidv1());
    uuid = localStorage.getItem('uuid');
  }

  // check if a fake sessionId exists in sessionStorage and set a new one if none exists
  let sessionId = sessionStorage.getItem('sessionId');
  if (sessionId === null) {
    sessionStorage.setItem('sessionId', uuidv1());
    sessionId = sessionStorage.getItem('sessionId');
  }

  const dpTrackInit = {
    site: () => {
      const gtmObj = {
        app: {
          name: 'Celebration IQ 2',
          subname: 'consumer',
          systemEnvironment,
          type: 'web',
        },
        page: {
          pageInfo: {
            destinationURL: window.location.href,
            language: locale, // language page is set to
            pageTitle: document.title,
            region: 'us', // us, uk, etc., based on region
            template: templates[info?.path] || '', // name of template type
          },
        },
        ecommerce: {
          action: {
            customer: {
              number: info?.location?.storeNumber,
              store: info?.location?.storeName,
              status: info?.location?.tenantStatus,
              testAccount: `${info?.location?.tenantStatus === 'internal'}`,
            },
          },
        },
        user: {
          anonymousId: uuid,
          loginState: 'anonymous',
          role: 'consumer - no role assigned',
          sessionId,
          type: 'consumer',
        },
        webClient: {
          internalIP: 'f',
          ipLocation: 'US|MN|Minneapolis',
        },
      };

      const mtmObj = {
        'mtm.startTime': new Date().getTime(),
        event: 'mtm.Start',
      };

      window._mtm.push(mtmObj); // send to Matomo Data Layer
      window.dataLayer.push(gtmObj); // send to Google Data Layer
    },
  };

  dpTrackInit.site();

  /* eslint-disable */

  // MTM TMS
  var d = document,
    g = d.createElement('script'),
    s = d.getElementsByTagName('script')[0];
  g.type = 'text/javascript';
  g.async = true;
  g.defer = true;
  g.src = mtm_container;
  s.parentNode.insertBefore(g, s);

  // GTM TMS
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + gtm_container;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', gtmId);
}