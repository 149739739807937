// TODO:
// remove this eslint-disable rule once we can get data from the API
/* eslint-disable max-len, no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseButtonModal from '../CloseButtonModal';

// dynamically import these until this data comes from the api
// this prevents us from loading ~250kb of text on every page
const PrivacyPolicy = dynamic(() => { return import('../PrivacyPolicy'); });
const TermsOfUse = dynamic(() => { return import('../TermsOfUse'); });
const PersonalInfo = dynamic(() => { return import('../PersonalInfo'); });
const IntellectualProperties = dynamic(() => { return import('../IntellectualProperties'); });
const MyHealthMyData = dynamic(() => { return import('../MyHealthMyData'); });

const FooterModal = ({
  isOpen,
  locale,
  onClose,
  siteData,
  variant,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  // TODO:
  // use these vars once we can get data from the API
  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(true);

  // TODO:
  // replace with actual endpoints once DecoPac has them
  // const endpoints = {
  //   privacy: 'legal/privacy/',
  //   terms: 'legal/terms/',
  //   info: 'legal/info/',
  //   ip: 'legal/ip/',
  // };

  // TODO:
  // use this hook once we can get data from the API
  // useEffect(() => {
  //   if (!variant) return undefined;

  //   if (isOpen) {
  //     const fetchData = async () => {
  //       const url = `${process.env.NEXT_PUBLIC_WOLVERINE_URL}api/${locale}/${endpoints[variant]}`;
  //       const resp = await fetch(url, {
  //         headers: {
  //           'Accept-Language': locale,
  //         },
  //       });

  // TODO:
  // this will probably be returned as json, not plain html
  //       const body = await resp.text();

  //       setData(body);
  //       setLoading(false);
  //     };

  //     setLoading(true);
  //     fetchData();
  //   }
  //   return () => {};
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isOpen, variant]);

  if (!variant) return undefined;

  // TODO:
  // remove these components once we get data from the API
  const getContent = () => {
    switch (variant) {
      case 'privacy':
        return (
          <PrivacyPolicy />
        );
      case 'terms':
        return (
          <TermsOfUse />
        );
      case 'info':
        return (
          <PersonalInfo />
        );
      case 'ip':
        return (
          <IntellectualProperties />
        );
      case 'health':
        return (
          <MyHealthMyData />
        );
      default:
        return '';
    }
  };

  return (
    <Dialog
      classes={{ paper: 'modal footer-modal' }}
      fullScreen={!matches}
      fullWidth
      maxWidth="lg"
      onClose={onClose}
      open={isOpen}
    >
      <CloseButtonModal
        onClose={onClose}
        dataContentName="footer modal"
        dataContentPiece="exit"
      />
      <div className="modal-top-border" style={{ backgroundColor: siteData?.primaryColor }} />
      <DialogContent tabIndex={-1}>
        <div className="text-color-wrapper">
          {getContent()}
        </div>
        {/* {loading && (
            <div className="loading">
              <span className="circle-loading-indicator" style={{ borderColor: siteData?.primaryColor }} />
            </div>
          )}
          {data && (
            // eslint-disable-next-line react/no-danger
            <div className="text-color-wrapper" dangerouslySetInnerHTML={{ __html: data }} />
          )} */}
      </DialogContent>
    </Dialog>
  );
};

FooterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  siteData: PropTypes.object.isRequired,
  variant: PropTypes.oneOf([
    '',
    'health',
    'info',
    'ip',
    'privacy',
    'terms',
  ]),
};

export default FooterModal;
